import { useState } from 'react'
import constate from 'constate'

type UseWarningModalProps = {
  isOpen: boolean;
  onContinue: () => void
  onCancel: () => void
  openModal: (onContinue: () => void, onCancel?: () => void) => void
  closeModal: () => void
}

const useWarningModalLogic = (): UseWarningModalProps => {
  const [isOpen, setIsOpen] = useState(false)
  const [onContinue, setOnContinue] = useState<() => void>(() => () => {})
  const [onCancel, setOnCancel] = useState<() => void>(() => () => {})

  const openModal = (continueHandler: () => void, cancelHandler?: () => void) => {
    setOnContinue(() => continueHandler)
    setOnCancel(() => cancelHandler || (() => { }))
    setIsOpen(true)
  }

  const closeModal = () => setIsOpen(false)

  return { isOpen, onContinue, onCancel, openModal, closeModal }
}

const [WarningModalProvider, useWarningModal] = constate(useWarningModalLogic)

export { WarningModalProvider, useWarningModal }

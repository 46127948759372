import styled from 'styled-components'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../../../shared/Card'
import BackLink from '../../backLink/BackLink'
import { ROUTES } from '../../../../constants/routes'
import ComparisonDetails from '../../comparisonDetails/ComparisonDetails'
import Table from './Table'
import { ReactComponent as DatabaseIcon } from '../../../../assets/Database.svg'
import { ReactComponent as TableIcon } from '../assets/Table.svg'
import { useComparedVariablesContext } from '../state/useComparedVariablesState'
import Spinner from '../../../shared/Spinner'
import { TableRowsProvider, useTableRowsContext } from '../state/useTableRows'
import { DetailsStateProvider, useDetailsStateContext } from '../state/useDetailsState'
import IconButton from '../../../shared/IconButton'
import { ReactComponent as EditIcon } from '../../../../assets/Edit.svg'
import AdvancedPagination from '../../advancedPagination/components/AdvancedPagination'
import { PaginationProvider } from '../../../../hooks/usePagination'
import { prepareDataForSubmit } from '../utils'
import Hint from '../../../shared/hint/Hint'
import { HINT_TEXT } from '../constants'
import { useFetchValues } from '../hooks/useFetchValues'

const WrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CardStyled = styled(Card)`
  padding: 0;
  margin: auto;
  min-width: 1136px;
  max-width: min-content;
  height: 100%;
  background: transparent;
  box-shadow: none;
`

const TitleStyled = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 8px;
  display: block;
`

const HeaderStickyBarWrapper = styled.div`
  position: sticky;
  z-index: 10001;
  width: 100%;
  top: 60px;
  margin-top: -20px;
`

const HeaderStickyBar = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 20px 20px 0;
  border-radius: 8px;
  margin-top: -5px;
`

const SpinnerStyled = styled(Spinner)`
  margin-top: 20px;
`

const NavigationWrapStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`

const TitleWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  :nth-child(2) {
    margin-bottom: 8px;
  }
`

const WarningContainerStyled = styled.div`
  padding-bottom: 10px;
  background: ${({ theme }) => theme.colors.white};
`

const TextContainerStyled = styled.div`
  display: flex;
  align-items: center;
  background-color: #fffde5;
  border: 1px solid #fbe277;
  padding: 8px 8px 0 8px;
  justify-content: center;
`

const PaginationWrapper = styled.div`
  & .MuiCard-root {
    min-width: 1228px !important;
  }
`

const BlueGap = styled.div`
  width: 100%;
  height: 25px;
  background: ${({ theme }) => theme.colors.blue.light};
`

export const useEmptyDataGuard = () => {
  const { comparedVariables, comparedVariable } = useComparedVariablesContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!comparedVariables.length && !comparedVariable) {
      navigate(ROUTES.DATASETS)
    }
  }, [comparedVariables, comparedVariable])
}

const ValuesComparison = () => {
  useEmptyDataGuard()
  const { tableRows } = useTableRowsContext()
  const { variables, datasets } = useDetailsStateContext()
  const navigate = useNavigate()
  useFetchValues()

  return (
    <WrapperStyled>
      <CardStyled>
        <HeaderStickyBarWrapper>
          <BlueGap />
          <HeaderStickyBar>
            <NavigationWrapStyled>
              <BackLink link={-1} pageName="variable results" />
              <IconButton
                onClick={() => navigate(ROUTES.DATASETS)}
                sx={{
                  display: 'flex',
                  gap: '8px',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '18.75px'
                }}
              >
                <EditIcon />
                Edit search
              </IconButton>
            </NavigationWrapStyled>
            {!tableRows && (
              <WarningContainerStyled>
                <TextContainerStyled>
                  <TitleStyled>Hang tight! Loading might take a few minutes...</TitleStyled>
                </TextContainerStyled>
              </WarningContainerStyled>
            )}
            {tableRows && (
              <>
                <TitleWrapStyled>
                  <TitleStyled>Possible values for the variable(s) you selected</TitleStyled>
                  <Hint withDefaultColor placement="top" title={HINT_TEXT} maxWidth={400} />
                </TitleWrapStyled>
              </>
            )}
          </HeaderStickyBar>
        </HeaderStickyBarWrapper>
        {tableRows && (
          <ComparisonDetails
            rows={[
              {
                icon: <TableIcon />,
                name: 'Variable(s)',
                items: variables
              },
              {
                icon: <DatabaseIcon />,
                name: 'Dataset(s)',
                items: datasets
              }
            ]}
          />
        )}
        {tableRows && (
          <PaginationWrapper>
            <AdvancedPagination />
          </PaginationWrapper>
        )}
        {!tableRows ? <SpinnerStyled /> : <Table />}
      </CardStyled>
      {tableRows && (
        <PaginationWrapper>
          <AdvancedPagination />
        </PaginationWrapper>
      )}
    </WrapperStyled>
  )
}

export default () => {
  const { comparedVariables, comparedVariable } = useComparedVariablesContext()
  const keywords = prepareDataForSubmit(comparedVariables.length ? comparedVariables : comparedVariable ? [comparedVariable] : [])

  return (
    <PaginationProvider rowsPerPage={75} initialRowsCount={keywords[0]?.variablesId?.length}>
      <DetailsStateProvider>
        <TableRowsProvider>
          <ValuesComparison />
        </TableRowsProvider>
      </DetailsStateProvider>
    </PaginationProvider>
  )
}

import { TRowData } from './definitions'
import { TDatasetsVariablesData, TVariable } from '../../../definitions/variable'

export const getVariablesFromDatasets = (datasets: TDatasetsVariablesData[]): TVariable[] =>
  datasets
    .flatMap(({ versions }) => versions)
    .flatMap(({ files }) => files)
    .flatMap(({ variables }) => variables)

const prepareVariables = (dataset: TDatasetsVariablesData): (TVariable & { fileName?: string; year?: string })[] =>
  dataset.versions.flatMap(({ files, year }) =>
    files.flatMap((file) =>
      file.variables.flatMap((variable) => ({
        ...variable,
        fileName: file.name,
        year
      }))))

const getRowsByVariables = (dataset: TDatasetsVariablesData): TRowData[] =>
  prepareVariables(dataset).map((variable) => ({
    year: variable.year,
    file: variable.fileName,
    varCode: variable.code,
    varDescription: variable.description,
    variableId: variable.id,
    valuesCount: variable.valuesCount
  }))

export const prepareDatasetsForTable = (datasets: TDatasetsVariablesData[]): any[] =>
  datasets.map((dataset) => {
    const [firstVar, ...restVars] = getRowsByVariables(dataset)
    return {
      id: dataset.dataset_id,
      name: dataset.dataset_name,
      shortName: dataset.dataset_short_name,
      year: firstVar.year,
      file: firstVar.file,
      varCode: firstVar.varCode,
      varDescription: firstVar.varDescription,
      variableId: firstVar.variableId,
      valuesCount: firstVar.valuesCount,
      datasetContinues: dataset.datasetContinues,
      versionContinues: dataset.versionContinues,
      subRows: restVars.map((row) => ({
        ...row,
        name: dataset.dataset_name,
        shortName: dataset.dataset_short_name,
        datasetContinues: dataset.datasetContinues,
        versionContinues: dataset.versionContinues
      }))
    }
  })

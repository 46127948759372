import React from 'react'
import styled from 'styled-components'
import Modal from '../../../../shared/modal/Modal'
import ModalContent from '../../../../shared/modal/ModalContent'
import Button from '../../../../shared/Button'
import { ReactComponent as WarningIcon } from '../../../../../assets/WarningCircle.svg'
import { useWarningModal } from '../../context/WarningModalContext'

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 500px;
  text-align: center;
`

const IconWrapper = styled.div`
  font-size: 36px;
  color: #ffcc00;
  padding-top: 24px;
`

const TitleStyled = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
`

const ButtonContainerStyled = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 20px;
`

const WarningModal: React.FC = () => {
  const { isOpen, onContinue, onCancel, closeModal } = useWarningModal()

  if (!isOpen) return null

  return (
    <Modal
      open={isOpen}
      closeModal={closeModal}
      withCloseButton
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#fffde5',
          border: '1px solid #fbe277',
          borderRadius: '8px'
        }
      }}
    >
      <ModalContent>
        <ContentStyled>
          <IconWrapper>
            <WarningIcon />
          </IconWrapper>
          <TitleStyled>This variable has &gt;1,000 possible values. Expect delays.</TitleStyled>
          <ButtonContainerStyled>
            <Button type="button" onClick={onCancel} variant="secondary" sx={{ width: 100 }}>
              Cancel
            </Button>
            <Button type="button" onClick={onContinue} variant="primary" sx={{ width: 100 }}>
              Continue
            </Button>
          </ButtonContainerStyled>
        </ContentStyled>
      </ModalContent>
    </Modal>
  )
}

export default WarningModal

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import pluralize from 'pluralize'
import { useQueryClient } from 'react-query'
import Card from '../../../shared/Card'
import BackLink from '../../backLink/BackLink'
import { ROUTES } from '../../../../constants/routes'
import ComparisonDetails from '../../comparisonDetails/ComparisonDetails'
import { ReactComponent as DatabaseIcon } from '../../../../assets/Database.svg'
import { ReactComponent as KeyIcon } from '../../../../assets/Key.svg'
import { ReactComponent as EditIcon } from '../../../../assets/Edit.svg'
import Table from './Table'
import { useKeywordsStateContext } from '../../datasets/state/useKeywordsState'
import { KEYWORD_DETAILS_QUERY_KEY, useKeywordDetailsQuery } from '../../../../queries/useKeywordDetailsQuery'
import Spinner from '../../../shared/Spinner'
import { getVariablesFromDatasets } from '../utils'
import IconButton from '../../../shared/IconButton'
import Hint from '../../../shared/hint/Hint'
import { HINT_TEXT } from '../constants'
import { PaginationProvider, usePaginationContext } from '../../../../hooks/usePagination'
import AdvancedPagination from '../../advancedPagination/components/AdvancedPagination'
import { TDatasetsVariablesData } from '../../../../definitions/variable'
import WarningModal from './modals/WarningModal'
import { WarningModalProvider } from '../context/WarningModalContext'

const WrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CardStyled = styled(Card)`
  padding: 0;
  min-width: 1136px;
  max-width: min-content;
  margin: auto;
  height: 100%;
  background: transparent;
  box-shadow: none;
`

const HeaderStickyBarWrapper = styled.div`
  position: sticky;
  z-index: 10001;
  width: 100%;
  top: 60px;
  margin-top: -20px;
`

const HeaderStickyBar = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 20px 20px 0;
  border-radius: 8px;
  margin-top: -5px;
`

const TitleStyled = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black};
  display: block;
  padding-bottom: 8px;
`

const NavigationWrapStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`

const BoldTextStyled = styled.span`
  font-weight: 500;
`

const SpinnerStyled = styled(Spinner)`
  margin: 20px 0;
`

const TitleWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  :nth-child(2) {
    margin-bottom: 8px;
  }
`

const BlueGap = styled.div`
  width: 100%;
  height: 25px;
  background: ${({ theme }) => theme.colors.blue.light};
`

const useDetailsRows = (datasets: any) => {
  const [keywordData] = useKeywordsStateContext().comparedKeywords

  if (!datasets) {
    return []
  }
  return [
    {
      icon: <KeyIcon />,
      name: 'Search term',
      items: [keywordData?.name]
    },
    {
      icon: <DatabaseIcon />,
      name: 'Dataset(s)',
      items: datasets?.map(({ dataset_name }: any) => dataset_name) ?? []
    }
  ]
}

const useEmptyDataHandler = () => {
  const navigate = useNavigate()
  const [keywordData] = useKeywordsStateContext().comparedKeywords

  useEffect(() => {
    if (!keywordData) {
      navigate(ROUTES.DATASETS)
    }
  }, [keywordData])
}

function useUseKeywordDetailsData() {
  const { startCount, rowsPerPage, page } = usePaginationContext()
  const {
    comparedKeywords: [keywordData]
  } = useKeywordsStateContext()
  return useKeywordDetailsQuery(
    {
      name: keywordData?.name,
      variablesId: keywordData?.variablesId
    },
    false,
    true,
    startCount,
    rowsPerPage,
    page
  )
}

const KeywordDetails = () => {
  useEmptyDataHandler()
  const {
    comparedKeywords: [keywordData]
  } = useKeywordsStateContext()
  const { data: datasets } = useUseKeywordDetailsData()
  const queryClient = useQueryClient()
  const allDataSets = queryClient.getQueryData<TDatasetsVariablesData[]>([KEYWORD_DETAILS_QUERY_KEY])

  const variables = getVariablesFromDatasets(allDataSets ?? [])
  const detailsRows = useDetailsRows(allDataSets)
  const navigate = useNavigate()

  return (
    <WrapperStyled>
      <CardStyled>
        <HeaderStickyBarWrapper>
          <BlueGap />
          <HeaderStickyBar>
            <NavigationWrapStyled>
              <BackLink link={ROUTES.DATASETS} pageName="main search results" />
              <IconButton
                onClick={() => navigate(ROUTES.DATASETS)}
                sx={{
                  display: 'flex',
                  gap: '8px',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '18.75px'
                }}
              >
                <EditIcon />
                Edit search
              </IconButton>
            </NavigationWrapStyled>

            {datasets && (
              <TitleWrapStyled>
                <TitleStyled>
                  Your search for variables addressing <BoldTextStyled>{`"${keywordData?.name}"`}</BoldTextStyled> found{' '}
                  <BoldTextStyled>{pluralize('variable', variables.length, true)}</BoldTextStyled> in{' '}
                  <BoldTextStyled>{pluralize('dataset', allDataSets?.length, true)}</BoldTextStyled>.
                </TitleStyled>
                <Hint withDefaultColor placement="top" title={HINT_TEXT} maxWidth={400} />
              </TitleWrapStyled>
            )}
          </HeaderStickyBar>
        </HeaderStickyBarWrapper>
        <WarningModalProvider>
          <WarningModal />

          {datasets && <ComparisonDetails rows={detailsRows} />}
          <AdvancedPagination />
          {datasets ? <Table tableData={datasets} /> : <SpinnerStyled />}
        </WarningModalProvider>
      </CardStyled>
      <AdvancedPagination />
    </WrapperStyled>
  )
}

export default () => {
  const {
    comparedKeywords: [keywordData]
  } = useKeywordsStateContext()
  const { isLoading } = useUseKeywordDetailsData()
  if (isLoading) {
    return (
      <WrapperStyled>
        <CardStyled>
          <SpinnerStyled />
        </CardStyled>
      </WrapperStyled>
    )
  }
  return (
    <PaginationProvider initialRowsCount={keywordData?.variablesId.length} rowsPerPage={75}>
      <KeywordDetails />
    </PaginationProvider>
  )
}
